.hero {
    display: flex;
    justify-content: space-between;
}

.left-h {
    padding: 15px;
    padding-top: 20px;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.right-h {
    flex: 1;
    position: relative;
    background-color: tomato;
}

.the-best-ad {
    margin-top: 50px;
    /* background-color: rgb(57, 54, 54); */
    background-color: rgb(214, 214, 158);
    border-radius: 40px;
    width: fit-content;
    padding: 20px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.the-best-ad>span {
    z-index: 2;
}

.the-best-ad>div {
    position: absolute;
    width: 100px;
    background-color: tomato;
    height: 80%;
    border-radius: 50px;
    left: 8px;
    z-index: 1;
}

.hero-text {
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-transform: uppercase;
    font-size: 70px;
    color: white;
    text-overflow: inherit;
    justify-content: flex-start;
}

.strok-text {
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.text-hh {
    font-size: 20px;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures {
    display: flex;
    gap: 10px;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    color: aliceblue;
    font-size: 30px;
}

.figures>div>span:nth-of-type(2) {
    color: rgb(208, 208, 208);
    text-transform: uppercase;
}

.btn {
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    cursor: pointer;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
}

.hero-buttons>div:nth-child(1) {
    color: white;
    background-color: tomato;
    width: 100px;
    margin-right: 20px;
}

.hero-buttons>div:nth-child(2) {
    color: white;
    background-color: transparent;
    width: 100px;
    border: 2px tomato solid;
}

.right-h>.btn {
    position: absolute;
    right: 80px;
    top: 50px;
    color: black;
}

.heart-rate {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #282c34;
    width: fit-content;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    right: 50px;
    top: 150px;
}

.heart-rate>:nth-child(2) {
    color: rgb(206, 206, 206);
}

.heart-rate>:nth-child(3) {
    color: white;
    font-size: 25px;
}


.hero-image {
    position: absolute;
    top: 180px;
    right: 150px;
    width: 400px;
}

.hero-image_back {
    position: absolute;
    top: 150px;
    right: 450px;
    width: 200px;
    height: 450px;
    z-index: -1;
}

.calories {
    display: flex;
    gap: 20px;
    background-color: #e6c7c7;
    border-radius: 5px;
    padding: 10px;
    width: 230px;
    position: absolute;
    top: 600px;
    right: 600px;
}

.calories>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    gap: 15px;
}

.hero-blur {
    width: 350px;
    height: 400px;
    left: 0;
}

.hero-blur-r {
    width: 350px;
    height: 400px;
    right: 300px;
    top: 400px;
    background-color: aqua;
}

@media screen and (max-width:768px) {
    .hero {
        flex-direction: column;
    }

    .hero-blur {
        width: 200px;
    }

    .the-best-ad {
        align-items: center;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-text {
        align-self: center;
        align-items: center;
        font-size: xx-large;
        gap: 10px;
    }

    .hero-text>div:nth-child(3) {
        font-size: small;
        letter-spacing: 1px;
        text-align: center;
    }
    .figures> div> span:nth-child(1){
        font-size: large;
        font-weight: bolder;
    }
    .figures> div> span:nth-child(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left:20px;
        top: 60px;
    }
    .calories{
        position: relative;
        top: 150px;
        left: 50px;
        width: 195px;
    }
    .calories>img{
        width: 40px;
    }
.hero-image{
    position: relative;
    left: 100px;
    top: 120px;
    width: 250px;
    align-self: center;
}
.hero-image_back{
    width: 250px;
    left: 20px;
    top: 50px;
}
.right-h>.btn {
    right: 10px;
    top: 70px;
    color: black;
}
}