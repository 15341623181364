.Reasons {
    padding: 0px 20px;
    margin-top: 50px;
    gap: 10px;
    display: flex;
}

.left-r {
    flex: 1 1;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
}

.left-r>img {
    object-fit: cover;
}

.left-r>:nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 500px;
}

.left-r>:nth-child(2) {
    width: auto;
    grid-column: 2/4;
    height: 250px;
}

.left-r>:nth-child(3) {
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}

.left-r>:nth-child(4) {
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
}

.right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.right-r>span {
    font-weight: bold;
    color: tomato;
    font-size: 30px;
}

.right-r>div {
    color: white;
    font-size: 45px;
    font-weight: bold;
}



.details-r {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.details-r>div {
    display: flex;
    text-transform: uppercase;
    font-size: 20px;
    gap: 20px;
}

.partners {
    display: flex;
    gap: 55px;
    justify-content: center;
}

.partners>img {
    margin-top: 10px;
    width: 70px;
}

.partners>img:hover {
    filter: drop-shadow(15px 5px 5px rgb(95, 255, 71));
}

@media screen and (max-width:786px) {
    .Reasons {
        flex-direction: column;
    }

    .details-r {
        margin-top: 20px;
    }

    .details-r>div {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        font-size: medium;
    }

    .details-r>div>img {
        height: 40px;
    }

    .details-r>div>span {
        font-size: small;
        text-align: left;
    }

    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-r>:nth-child(1) {
        width: 100px;
        height: 250px;
    }

    .left-r>:nth-child(2) {
        width: 150px;
    }

    .left-r>:nth-child(3) {
        width: 300px;
        margin-left: -150px;
    }
    .left-r>:nth-child(4) {
        display: none;
    }
    .partners {
        gap: 25px;
        margin-bottom: 40px;
    }
    .partners>img{
        width: 50px;
    }
}