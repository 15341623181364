.testimonials {
    margin-top: 80px;
    display: flex;
    gap: 10px;
    padding: 0 2rem;
}

.left-t {
    display: flex;
    flex: 1 1;
    gap: 25px;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: left;
    align-items: flex-start;
    color: white;
}

.left-t>:nth-child(1) {
    color: tomato;
    font-weight: bold;
    font-size: 20px;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3) {
    font-size: 55px;
    font-weight: bold;
}

.left-t>:nth-child(1) {
    text-transform: none;
    text-align: justify;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 50px;

}

.right-t {
    display: flex;
    flex: 1;
    position: relative;
}

.right-t>img {
    position: absolute;
    width: 300px;
    height: 350px;
    object-fit: cover;
    right: 150px;
    top: 100px;
}

.right-t>:nth-child(1) {
    position: absolute;
    width: 300px;
    height: 350px;
    border: 2px solid tomato;
    background-color: transparent;
    right: 170px;
    top: 70px;
}

.right-t>:nth-child(2) {
    position: absolute;
    width: 300px;
    height: 350px;
    background: tomato;
    right: 120px;
    top: 120px;
}


.right-t .arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: -7rem;
    left: 9rem;
}

.right-t .arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:786px) {
    .testimonials {
        flex-direction: column;
    }

    .left-t>:nth-child(2),
    .left-t>:nth-child(3) {
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: xx-large;
    }

    .left-t>:nth-child(4) {
        text-align: left;
    }

    .left-t>:nth-child(5) {
        text-align: left;
        font-size: small;
    }

    .right-t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .right-t>div {
        display: none;
    }

    .right-t>img {
        position: relative;
        right: 0px;
        top: 10px;
        align-self: center;
    }

    .right-t:last-child() {
        display: block;
    }

    .right-t .arrows {
        gap: 1rem;
        bottom: -5rem;
        left: 5rem;
    }

    .right-t .arrows>img {
        width: 3rem;
        cursor: pointer;
    }
}