/* *{
  background-color: #282c34;
} */
body {
  background-color: #282c34;
  margin: 0px;
  padding: 0px;

}

::-webkit-scrollbar {
  display: none;
}

.btn {
  background-color: white;
  padding: 10px;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}


.strok-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}



.App {
  text-align: center;
  margin-bottom: 200px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.blur {
  background-color: rgba(253, 120, 43, 0.7);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -10;
}