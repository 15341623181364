.programs-header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    font-weight: bold;
    color: white;
    margin-top: 10px;
    font-size: 50px;
    justify-content: center;
}


.plans-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.plan {
    display: flex;
    flex-direction: column;
    background-color: slategray;
    color: white;
    align-items: flex-start;
    gap: 30px;
    padding: 15px;
    width: 320px;
    height: 400px;
}

.feature {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 10px;
    align-items: flex-start;

}

.plan:nth-child(2) {
    background-color: tomato;
    background: linear-gradient(45deg, #e6a964, tomato);
    transform: scale(1.1);
}

.btn {
    width: 150px;
}

.plans-blur-left {
    width: 500px;
    height: 400px;
    top: 1700px;
    left: 0px;
    background-color: aqua;
}

.plans-blur-right {
    width: 500px;
    height: 400px;
    top: 1900px;
    left: 800px;
    background-color: rgb(255, 204, 0);
}

@media screen and (max-width:786px) {
    .plans {
        flex-direction: column;
    }
.programs-header{
    margin-top: 50px;
    gap: 15px;
    flex-direction: column;
    font-size:xx-large;
}
    .plan {
        width: 250px;
    }

    .plans>:nth-child(2) {
        width: 240px;
    }
}